import React, { useState, createContext, useEffect } from "react"

const ThemeContext = createContext(false)


const ThemeProvider = ({children}) => {
    const [darkMode, setDarkMode] = useState(false)
    const supportsDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches === true
    useEffect(() => {
        setDarkMode(supportsDarkMode)
    }, [])
    return (
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
        {children}
      </ThemeContext.Provider>
    )
}

export default ThemeContext

export { ThemeProvider }
